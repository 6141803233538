<template>
  <swiper
    class="mySwiper"
    :spaceBetween="30"
    :autoplay="{
      delay: 2500,
      disableOnInteraction: false,
    }"
    :modules="modules"
  >
    <swiper-slide v-for="(image, index) in packageData.images" :key="index"
      ><img :src="image.image" />
    </swiper-slide>
  </swiper>

  <section class="umrah_details mt-3 mb-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 col-md-12 col-12">
          <div class="trip_details">
            <div
              class="text-lastpage d-flex flex-row flex-wrap flex-md-nowrap align-items-start justify-content-between"
            >
              <div :class="['d-flex', 'w-100', 'flex-column', 'mb-3']">
                <h4>{{ packageData.title }}</h4>

                <div class="d-flex align-items-center justify-content-between">
                  <div class="d-flex align-items-center">
                    <p class="ms-2">{{ packageData.launch_city?.title }}</p>
                    <div
                      class="d-flex align-items-center justify-content-between"
                      v-for="(dist, index) in packageData.destinations"
                      :key="index"
                    >
                      <div
                        class="d-flex align-items-center justify-content-center"
                      >
                        <img
                          src="../../assets/media/image/fi_7118033.png"
                          alt=""
                          class="ms-2 size_image_icon"
                          :style="{ transform: rtl ? '' : 'rotate(180deg)' }"
                        />
                      </div>
                      <p class="ms-2">{{ dist.city.title }}</p>
                    </div>
                  </div>

                  <div
                    class="d-flex align-items-center flex-column w-50 flex-column-reverse"
                  >
                    <div class="d-flex align-items-center night">
                      <img
                        src="../../assets/media/image/umrah/icons/clock.svg"
                        alt=""
                        class="ms-1 size_image_icon"
                      />
                      <p class="ms-1">
                        {{ totalNights + 1 }} {{ $t("days") }} ,
                      </p>
                      <p class="night">{{ totalNights }} {{ $t("nights") }}</p>
                    </div>

                    <div
                      :class="[
                        'd-flex',
                        'align-items-center',
                        'w-50',
                        'pt-2',
                        'flex-grow-1',
                      ]"
                    >
                      <img
                        src="../../assets/media/image/calendar.png"
                        alt=""
                        class="ms-1"
                      />
                      <p>
                        {{ $t("date of Travel") }} {{ packageData.trip_date }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="rates pt-2 w-25 d-flex justify-content-center flex-column"
              >
                <p class="fw-bold text-center text-capitalize">
                  {{ packageData.star_category?.title }}
                </p>
                <rate :rates="packageData.star_category?.star_count"></rate>
              </div>
            </div>
            <div class="discarbtion mt-3">
              <div class="d-flex align-items-center gap-3 flex-wrap">
                <h4>
                  {{ $t("Trip_description") }}
                </h4>
                <div class="d-flex align-items-center">
                  <!-- <img src="../../assets/media/image/airplane.png" alt="" /> -->
                  <p class="details-info d-flex flex-wrap">
                    <span
                      class="service p-0 mb-2 d-flex align-items-center"
                      v-for="(service, index) in packageData.package_services"
                      :key="index"
                    >
                      <div class="icon mx-2 mt-0">
                        <i
                          class="fa-solid"
                          :class="{
                            'fa-hotel': service == 'Hotel',
                            'fa-plane-departure': service == 'Flight',
                            'fa-passport': service == 'Visa',
                            'fa-car-side': service == 'Transportations',
                            'fa-shield': service == 'Insurance',
                          }"
                        ></i>
                      </div>
                      {{ $t(`${service}`) }}
                    </span>
                  </p>
                </div>
              </div>
              <p>{{ packageData.description }}</p>
            </div>
            <div
              class="Residence mt-3 d-flex gap-3 align-items-center"
              v-if="packageData.hotels"
            >
              <img
                src="../../assets/media/image/umrah/icons/hotel.svg"
                alt=""
              />
              <h4>{{ $t("Residence") }}</h4>
            </div>
            <div class="hotel-grid" v-if="packageData.hotels.length">
              <div
                class="card-lastpage p-0 overflow-hidden"
                v-for="(hotel, index) in packageData.hotels"
                :key="index"
              >
                <swiper
                  class="mySwiper"
                  :spaceBetween="30"
                  :pagination="{
                    clickable: true,
                    number: 3,
                  }"
                  :modules="modules"
                >
                  <swiper-slide
                    v-for="(image, index) in hotel.images"
                    :key="index"
                    ><img :src="image.image" @error="setDefaultImage" />
                  </swiper-slide>
                </swiper>
                <h4 class="mt-3 px-2">
                  {{ hotel.city.title }} (
                  {{ packageData.destinations[index]?.nights_count }}

                  {{ $t("nights") }} )
                </h4>
                <div class="icons-text d-flex align-items-center mt-2 px-2">
                  <img
                    src="../../assets/media/image/buliding.png"
                    class="mx-2"
                    alt=""
                  />
                  <h6 class="mb-0">{{ $t("hotel") }} {{ hotel.title }}</h6>
                </div>
                <div
                  class="d-flex align-items-center justify-content-between mt-2 mb-3 px-2"
                >
                  <div class="d-flex align-items-center">
                    <img
                      src="../../assets/media/image/gps.png"
                      class="mx-2"
                      alt=""
                    />
                    <h6 class="mb-0">
                      {{
                        hotel.address.split(" ").length <= 5
                          ? hotel?.address
                          : hotel?.address.split(" ").slice(0, 7).join(" ") +
                            "..."
                      }}
                    </h6>
                  </div>
                  <span
                    class="hotel-details"
                    @click="
                      $router.push(`/hotel/details/${hotel.hotel_code}/1`)
                    "
                    >{{ $t("more") }}</span
                  >
                </div>
              </div>
            </div>

            <div class="flght-lastpage-all" v-if="packageData.flight.length">
              <div class="flight d-flex gap-3 align-items-center mt-3">
                <img
                  src="../../assets/media/image/umrah/icons/take-off.svg"
                  alt=""
                />
                <h4>{{ $t("flights") }}</h4>
              </div>

              <div
                class="all-card-flight-more mt-3"
                v-for="(flight, index) in packageData.flight"
                :key="index"
              >
                <div
                  class="flight-card d-flex gap-3 align-items-center justify-content-between px-3 py-4"
                >
                  <h6>{{ flight?.flight_company?.name }}</h6>
                  <div class="airplane-img sm-none">
                    <img src="../../assets/media/image/airport.png" alt="" />
                  </div>
                  <p>{{ flight.launch_airport.title }}</p>
                  <div
                    class="d-flex flex-column justify-content-center text-center"
                  >
                    <p>
                      {{
                        flight.trip_number == ""
                          ? "QR 1301"
                          : flight.trip_number
                      }}
                    </p>
                    <div
                      class="d-flex align-items-center justify-content-between"
                    >
                      <img
                        src="../../assets/media/image/fi_7118033.png"
                        alt=""
                      />
                      <div class="plane-line"></div>
                      <img
                        src="../../assets/media/image/fi_7118033.png"
                        alt=""
                      />
                    </div>
                    <p>{{ $t(flight.trip_degree) }}</p>
                  </div>
                  <p>{{ flight.arrival_airport.title }}</p>
                </div>
              </div>
            </div>

            <div
              class="transportation"
              v-if="packageData.transportation.length"
            >
              <div class="card-cars d-flex gap-3 align-items-center mt-3">
                <img
                  src="../../assets/media/image/umrah/icons/car.svg"
                  alt=""
                />
                <h4>{{ $t("Transportation") }}</h4>
              </div>
              <div
                class="transportation"
                v-for="trans in packageData.transportation"
                :key="trans.id"
              >
                <div>
                  <h4 class="fw-bold">
                    {{ $t("Itinerary") }}: {{ trans.car_route.text }}
                  </h4>
                  <div
                    v-for="(car, index) in trans.cars"
                    :key="index"
                    class="d-flex justify-content-between"
                  >
                    <div>
                      <h3 class="fw-bold">{{ car.car_type.title }}</h3>
                      <div class="d-flex align-items-center">
                        <img
                          src="../../assets/media/image/profile-2user.png"
                          alt=""
                          class="ms-2"
                        />
                        <p>
                          {{ $t("number of passengers") }}
                          {{ car.car_type.max_number }}
                        </p>
                      </div>
                    </div>
                    <div class="photo-img">
                      <img :src="car.car_type.image" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="cars-text mt-3">
              <div
                class="d-flex align-items-center justify-content-between w-100"
              >
                <h4 class="header-cars w-50" v-if="packageData?.included">
                  {{ $t("included") }}
                </h4>
                <h4 class="header-cars w-50" v-if="packageData?.not_included">
                  {{ $t("not_included") }}
                </h4>
              </div>
              <div
                class="d-flex align-items-start justify-content-between w-100 mt-2"
              >
                <div
                  v-if="packageData?.included"
                  class="include d-flex align-items-baseline justify-content-center w-50"
                >
                  <i class="fa-solid fa-check mx-1" style="color: #63e6be"></i>
                  <p v-html="packageData.included" class="mb-0 w-100"></p>
                </div>
                <div
                  v-if="packageData?.not_included"
                  class="include d-flex align-items-baseline justify-content-center w-50"
                >
                  <i
                    class="fa-solid fa-xmark fa-xs mx-1"
                    style="color: #ff0000"
                  ></i>
                  <p v-html="packageData.not_included" class="mb-0 w-100"></p>
                </div>
              </div>
            </div>
            <div class="services" v-if="packageData.additional_services.length">
              <h4 class="mt-3 fw-bold">{{ $t("Additional services") }}</h4>
              <div
                class="additional-services flex-column gap-3 mt-3"
                v-for="item in packageData.additional_services"
                :key="item.id"
              >
                <div class="d-flex align-items-center gap-2">
                  <img
                    src="../../assets/media/image/umrah/icons/right.svg"
                    alt=""
                  />
                  <p>
                    {{ item.title }}
                    <i class="fa-solid fa-arrow-left-long mx-2"></i>
                  </p>
                  <p>
                    {{ $t("Service cost") }}:
                    <span class="fw-bold">{{ item.selling }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 col-12">
          <div class="main-leftsec">
            <div class="rooms">
              <div class="hotel_title">
                <div class="identify-rooms text-center"></div>
                <div
                  class="form-group px-3"
                  v-if="!nationtality || availiable_nationality"
                >
                  <label class="form-label">{{
                    $t("choose_nationality")
                  }}</label>
                  <Multiselect
                    v-model="nationtality"
                    :options="nations"
                    label="title"
                    track-by="id"
                    :clear-on-select="true"
                    :placeholder="$t('choose_nationality')"
                    select-label=""
                    :hide-selected="true"
                    @keyup="fetchNationality"
                    @select="fetchRoomsByNationality(nationtality)"
                  >
                  </Multiselect>
                </div>
                <div
                  class="form-group px-3"
                  v-else-if="nationtality && !availiable_nationality"
                >
                  <label class="form-label">{{
                    $t("choose_nationality")
                  }}</label>
                  <Multiselect
                    v-model="nationtality"
                    :options="nations"
                    label="title"
                    track-by="id"
                    :clear-on-select="true"
                    :placeholder="$t('choose_nationality')"
                    select-label=""
                    :hide-selected="true"
                    @keyup="fetchNationality"
                    @select="fetchRoomsByNationality(nationtality)"
                  >
                  </Multiselect>
                </div>
              </div>
              <div
                v-if="
                  rooms &&
                  extra?.is_available == 'AVAILABLE' &&
                  nationtality &&
                  !availiable_nationality
                "
              >
                <div
                  class="room mb-3"
                  v-for="room in packageData.minimum_price"
                  :key="room.room_id"
                >
                  <div class="row">
                    <div
                      class="col-12 d-flex align-items-center justify-content-start"
                    >
                      <h4 class="room-title">
                        <i class="fa-solid fa-bed"></i> {{ room.room_name }}
                      </h4>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label>{{ $t("number_of_adults") }}</label>
                        <Multiselect
                          v-model="room.selected_adult"
                          :options="room.adults"
                          label="title"
                          track-by="id"
                          :clear-on-select="true"
                          :placeholder="$t('choose')"
                          select-label=""
                          :hide-selected="true"
                          @update:model-value="calc_total_adult"
                          :custom-label="adultNumber"
                        >
                        </Multiselect>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label>{{ $t("number_of_childrens") }}</label>
                        <Multiselect
                          v-model="room.selected_child"
                          :options="room.childrens"
                          label="title"
                          track-by="id"
                          :clear-on-select="true"
                          :placeholder="$t('choose')"
                          select-label=""
                          :hide-selected="true"
                          @update:model-value="calc_total_child"
                          :custom-label="adultNumber"
                        >
                        </Multiselect>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                v-else-if="
                  (rooms && extra?.is_available == 'AVAILABLE') ||
                  (nationtality && !availiable_nationality)
                "
              >
                <div
                  class="room mb-3"
                  v-for="room in packageData.minimum_price"
                  :key="room.room_id"
                >
                  <div class="row">
                    <div
                      class="col-12 d-flex align-items-center justify-content-start"
                    >
                      <h4 class="room-title">
                        <i class="fa-solid fa-bed"></i> {{ room.room_name }}
                      </h4>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label>{{ $t("number_of_adults") }}</label>
                        <Multiselect
                          v-model="room.selected_adult"
                          :options="room.adults"
                          :clear-on-select="true"
                          :placeholder="$t('choose')"
                          select-label=""
                          :hide-selected="true"
                          @update:model-value="calc_total_adult"
                          :custom-label="adultNumber"
                        >
                        </Multiselect>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label>{{ $t("number_of_childrens") }}</label>
                        <Multiselect
                          v-model="room.selected_child"
                          :options="room.childrens"
                          :clear-on-select="true"
                          label="title"
                          track-by="id"
                          :placeholder="$t('choose')"
                          select-label=""
                          :hide-selected="true"
                          @update:model-value="calc_total_child"
                          :custom-label="adultNumber"
                        >
                        </Multiselect>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="services mb-2">
                  <div class="identify-rooms">
                    <h5>{{ $t("choose services") }} ({{ $t("optional") }})</h5>
                    <div class="">{{ $t("choose one or more services") }}</div>
                  </div>

                  <div
                    class="service"
                    v-for="(service, index) in selected_services"
                    :key="index"
                  >
                    <div class="row">
                      <div class="col-lg-6 col-md-12 col-12">
                        <div class="form-group">
                          <label>{{ $t("services") }}</label>
                          <Multiselect
                            v-model="service.service_obj"
                            :options="fetchedServices"
                            label="title"
                            track-by="id"
                            :clear-on-select="true"
                            :placeholder="$t('choose')"
                            select-label=""
                            :hide-selected="true"
                          >
                          </Multiselect>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-9 col-9">
                        <div class="form-group">
                          <label>{{ $t("count") }}</label>
                          <input
                            type="number"
                            class="form-control"
                            placeholder="0"
                            v-model="service.count"
                            @keyup="calcServiceSelling"
                            :disabled="service.service_obj.id == undefined"
                          />
                        </div>
                      </div>
                      <div
                        class="col-lg-2 col-md-3 col-3 d-flex align-items-center"
                      >
                        <button
                          class="btn add"
                          @click="addService()"
                          v-if="index == 0"
                        >
                          <i class="fa-solid fa-plus"></i>
                        </button>

                        <button
                          class="btn minus"
                          @click="removeService(index)"
                          v-else
                        >
                          <i class="fa-solid fa-minus"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-else class="text-center mb-2">
                <!-- {{ $t("rooms don't exist") }} -->
                {{ $t("Choose nationality first") }}
              </div>
            </div>

            <!--
             v-if="this.adults_selling != null || this.child_selling != null" -->
            <div
              class="end-sec"
              v-if="
                (this.total_adults_price != 0 &&
                  this.packageData.status == 'ACTIVE') ||
                (this.total_child_price != 0 &&
                  this.packageData.status == 'ACTIVE')
              "
            >
              <div class="head-end">
                <h4>{{ $t("Price summary") }}</h4>
              </div>
              <div class="text-end">
                <p>
                  {{ $t("Cost for adults") }} (x{{ this.total_adults_count }})
                </p>
                <!-- <p>{{ this.adults_selling == null ? 0 : convertedPrice(this.adults_selling.price) }} {{ coinCode }}</p>  -->
                <p>
                  {{ convertedPriceFromSystem(this.total_adults_price) }}
                  {{ $store.state.coin }}
                </p>
              </div>
              <div class="text-end">
                <p>
                  {{ $t("Cost for children") }} (x{{ this.total_child_count }})
                </p>
                <p>
                  {{ convertedPriceFromSystem(this.total_child_price) }}
                  {{ $store.state.coin }}
                </p>
              </div>
              <div class="text-end">
                <p>{{ $t("total service cost") }}</p>
                <p>
                  {{ convertedPriceFromSystem(this.service_selling) }}
                  {{ $store.state.coin }}
                </p>
              </div>
              <div class="text-end">
                <p>{{ $t("Total costs") }}</p>
                <p>
                  {{ convertedPriceFromSystem(total_selling) }}
                  {{ $store.state.coin }}
                </p>
              </div>
              <div class="text-end">
                <p>{{ $t("Added tax") }}</p>
                <p>{{ tax_value }} %</p>
              </div>
              <hr />
              <div class="total">
                <div class="text-end">
                  <p>{{ $t("Total after tax") }}</p>
                  <p>
                    {{ convertedPriceFromSystem(total_selling_after_tax) }}
                    {{ $store.state.coin }}
                  </p>
                </div>
                <button
                  type="button"
                  class="btn btn-success pocke"
                  @click="savePackageData()"
                >
                  {{ $t("Book now") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import rate from "@/components/rate.vue";
import Multiselect from "vue-multiselect";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import moment from "moment";

export default {
  name: "umrah-package",
  data() {
    return {
      availiable_nationality: false,
      package_id: this.$route.params.id,
      nations: [],
      nationtality:
        JSON.parse(sessionStorage.getItem("searchData") || "{}")
          .nationalityValue || null,
      extra: null,
      packageData: {
        launch_city: {},
        base_price: {},
        star_category: {},
        transportation: [],
        hotels: [],
        flight: [],
        additional_services: [],
      },
      minimum_price: [],
      services: [],
      serviceValue: "",
      selected_services: [
        {
          service_obj: "",
          count: 0,
        },
      ],
      coinCode: localStorage.getItem("coinCode")
          ? localStorage.getItem("coinCode")
          : JSON.parse(localStorage.getItem("coinCodes"))[0]?.title || "USD",
      fetchedServices: [],
      dateValue: "",
      adults_selling: null,
      child_selling: null,
      service_selling: null,
      tax_value: 0,
      rate_package: null,
      package_selected_rooms: {},
      total_adults_price: 0,
      total_child_price: 0,
      total_adults_count: 0,
      total_child_count: 0,
      rtl: localStorage.getItem("lang") == "ar" ? true : false,
      totalNights: null,
      rooms: [],
    };
  },
  components: {
    rate,
    Multiselect,
    // Datepicker: VueDatepickerUi,
    Swiper,
    SwiperSlide,
  },
  computed: {
    total_selling() {
      let total_selling =
        this.total_adults_price + this.total_child_price + this.service_selling;
      return total_selling;
    },
    total_selling_after_tax() {
      let total_selling_after_tax =
        this.total_selling + this.total_selling * (this.tax_value / 100);
      return total_selling_after_tax;
    },
  },
  methods: {
    adultNumber(adultNum) {
      if (
        !adultNum ||
        adultNum?.title === undefined ||
        isNaN(adultNum?.price)
      ) {
        return "";
      }

      return `${adultNum.title}(${
        isNaN(adultNum.price)
          ? ""
          : this.convertedPriceFromSystem(adultNum.price)
      })`;
    },
    setDefaultImage(event) {
      event.target.src = localStorage.getItem("DefaultImage");
      this.isError = true; // Set isError to true when an error occurs
    },
    calc_total_adult() {
      let total_adult_selling = 0;
      let total_adults_count = 0;

      this.packageData.minimum_price.forEach((room) => {
        total_adult_selling =
          total_adult_selling +
          (room.selected_adult.price == undefined
            ? 0
            : room.selected_adult.price);
        total_adults_count =
          total_adults_count +
          (room.selected_adult.count == undefined
            ? 0
            : room.selected_adult.count);
      });

      this.total_adults_price = total_adult_selling;
      this.total_adults_count = total_adults_count;
    },
    calc_total_child() {
      let total_child_selling = 0;
      let total_child_count = 0;

      this.packageData.minimum_price.forEach((room) => {
        total_child_selling =
          total_child_selling +
          (room.selected_child.price == undefined
            ? 0
            : room.selected_child.price);
        total_child_count =
          total_child_count +
          (room.selected_child.count == undefined
            ? 0
            : room.selected_child.count);
      });

      this.total_child_price = total_child_selling;
      this.total_child_count = total_child_count;
    },
    calcServiceSelling() {
      let total_service_selling = 0;
      this.selected_services.forEach((ele) => {
        total_service_selling =
          total_service_selling + ele.service_obj.selling * ele.count;
        this.service_selling = total_service_selling;
      });
    },
    fetchPackage() {
      axios
        .post(`/fetch_package_by_package_id`, { package_id: this.package_id })
        .then(({ data }) => {
          this.packageData = data.data;
          // console.log(this.packageData);
          // console.log(this.packageData.status);
          this.minimum_price = data.data.minimum_price;
          this.rate_package = parseInt(this.packageData.star_count);
          // console.log(this.minimum_price)
          this.fetchedServices = data.data.additional_services;

          // console.log(this.fetchedServices);
          let totalNights = 0;
          for (const destination of data.data.destinations) {
            totalNights += destination.nights_count;
            // console.log(destination.nights_count)
          }
          this.totalNights = totalNights;
          // console.log(this.totalNights)

          if (this.packageData.hotels.length > 0) {
            this.packageData.hotels.forEach((ele) => {
              ele.rooms = ele.rooms.map((room) => {
                return {
                  ...room,
                  number_of_adults: 0,
                  number_of_childrens: 0,
                  adults: [],
                  childrens: [],
                  selected_adult: {},
                  selected_child: {},
                };
              });
            });

            this.rooms = this.packageData.hotels[0].rooms;
            // console.log(this.rooms);
          }

          this.packageData.minimum_price = this.packageData.minimum_price.map(
            (room) => {
              return {
                ...room,
                adults: [],
                childrens: [],
                selected_adult: {},
                selected_child: {},
              };
            }
          );

          // this.packageData.minimum_price.forEach((room) => {
          //   for (let i = 1; i < 36; i++) {
          //     room.adults.push({
          //       id: i,
          //       count: i,
          //       price: i * room.adult,
          //       title: `${i} ${this.$t("adult")} (${i * room.adult})`,
          //     });

          //     room.childrens.push({
          //       id: i,
          //       count: i,
          //       price: i * room.child,
          //       title: `${i} ${this.$t("child")} (${i * room.child})`,
          //     });
          //   }
          // });
          this.packageData.minimum_price.forEach((room) => {
            for (
              let i = this.packageData.min_adt_book;
              i <= this.packageData.max_adt_book;
              i++
            ) {
              room.adults.push({
                id: i,
                count: i,
                price: i * room.adult,
                title: `${i} ${this.$t("adult")} `,
              });
            }
            for (
              let i = this.packageData.min_chd_book;
              i <= this.packageData.max_chd_book;
              i++
            ) {
              room.childrens.push({
                id: i,
                count: i,
                price: i * room.child,
                title: `${i} ${this.$t("child")} `,
              });
            }
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error?.response?.data.message || "error",
            icon: "error",
          });
        });
    },
    fetchNationality(event) {
      const searchText = { word: event.target.value };
      axios
        .post(`/fetch_custom_countries`, searchText)
        .then((res) => (this.nations = res.data.data.data))
        .catch((err) => console.log(err));
    },
    fetchRoomsByNationality(nation) {
      // console.log(nation);
      axios
        .post("fetch_package_nationalities_by_package_and_nationality_id", {
          package_id: this.$route.params.id,
          nationality_id: nation.id,
        })
        .then((res) => {
          this.extra = res.data.data;
          this.availiable_nationality = true;
          // console.log(this.extra);
          // console.log(this.packageData.minimum_price);
          // New array to store the sums
          if (this.extra.is_available == "AVAILABLE") {
            this.packageData.minimum_price.forEach((room) => {
              this.extra.nationality_rooms.forEach((extraRoom) => {
                // console.log(extraRoom, "extraRoom");
                room.adults.forEach((adult) => {
                  if (extraRoom.room_id == room.room_id) {
                    adult.price += extraRoom.adult_selling;
                    adult.title = `${adult.count} ${this.$t("adult")} (${
                      adult.price
                    })`;
                  }
                  // console.log(adult.title, "adult.title");
                  // console.log(adult.price, "adult.price");
                });
                room.childrens.forEach((child) => {
                  if (extraRoom.room_id == room.room_id) {
                    child.price += extraRoom.child_selling;
                    child.title = `${child.count} ${this.$t("child")} (${
                      child.price
                    })`;
                    // console.log(child.title, "child.title");
                    // console.log(child.price, "child.price");
                  }
                });
              });
            });
          } else {
            Swal.fire({
              title: "",
              text: this.$t("this_nationality_is_not_available"),
              icon: "error",
            });
          }
          // console.log(this.packageData.minimum_price);
        });
    },
    addService() {
      this.selected_services.push({
        service_obj: {},
        count: 0,
      });
    },
    removeService(index) {
      // Ensure the service exists before attempting to access its properties
      if (this.selected_services[index]) {
        // Subtract the selling amount of the service being removed
        this.service_selling -=
          this.selected_services[index].service_obj.selling *
          this.selected_services[index].count;

        // Remove the service from the list of selected services
        this.selected_services.splice(index, 1);
      }
    },
    savePackageData() {
      let packageData = {
        package_id: this.$route.params.id,
        package_services: [],
        date: moment(new Date()).format("DD-MM-YYYY"),
        toDate:
          this.packageData.days == "Multi Days"
            ? this.packageData.to_date
            : this.packageData.trip_date,
        package_rooms: [],
      };

      this.selected_services.forEach((ele) => {
        if (ele.service_obj.id != undefined && ele.count != 0) {
          packageData.package_services.push({
            service_id: ele.service_obj.id,
            count: ele.count,
          });
        }
      });

      this.packageData.minimum_price.forEach((room) => {
        if (
          room.selected_adult.count != undefined ||
          room.selected_child.count != undefined
        ) {
          room.room_ids.forEach((roomId) => {
            if (roomId != null) {
              packageData.package_rooms.push({
                package_hotel_room_id: roomId,
                adult_count: isNaN(room.selected_adult.count)
                  ? 0
                  : room.selected_adult.count,
                child_count: isNaN(room.selected_child.count)
                  ? 0
                  : room.selected_child.count,
              });
            }
          });
        }
      });

      localStorage.setItem("packageData", JSON.stringify(packageData));

      let packageDetails = {
        image: this.packageData.main_image,
        title: this.packageData.title,
        // star_count: this.packageData.star_count,
        from_date:
          this.packageData.days == "Multi Days"
            ? this.packageData.from_date
            : this.packageData.trip_date,
        to_date:
          this.packageData.days == "Multi Days"
            ? this.packageData.to_date
            : this.packageData.trip_date,
        star_count: this.packageData.star_category?.star_count,
        trip_date: this.packageData.trip_date,
      };
      localStorage.setItem("packageDetails", JSON.stringify(packageDetails));

      let packagePrices = {
        total_adults_price: this.total_adults_price,
        total_child_price: this.total_child_price,
        total_selling: this.total_selling,
        tax_value: this.tax_value,
        total_selling_after_tax: this.total_selling_after_tax,
        service_selling: this.service_selling,
      };
      localStorage.setItem("packagePrices", JSON.stringify(packagePrices));

      this.$router.push("/bookPackage");
    },

    DateFormat(trip_date) {
      return moment(trip_date, "DD-MM-YYYY").format("ll");
    },
  },
  setup() {
    return {
      modules: [Autoplay, Pagination, Navigation],
    };
  },
  mounted() {
    this.fetchPackage();
    this.coinCode = this.$store.state.umrah_coin;
    // console.log(this.packageData, "packageData");
  },

  updated() {
    this.coinCode = localStorage.getItem("coinCode")
        ? localStorage.getItem("coinCode")
        : JSON.parse(localStorage.getItem("coinCodes"))[0]?.title || "USD";
  },
  watch: {
    coinCode() {
      this.coinCode = localStorage.getItem("coinCode")
        ? localStorage.getItem("coinCode")
        : "USD";
    },
  },
};
</script>

<style>
.size_image_icon {
  width: 15px;
}
.umrah_details .hotel-grid .card-lastpage h6 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: calc(var(--line-height) * 1em);
  line-height: var(--line-height);
  --line-height: 1.3;
  font-size: 0.8rem !important;
}
.all-card-flight-more .flight-card h6 {
  font-size: 0.8rem !important;
}
.size_image_icon {
  width: 18px;
}
.night {
  width: 50%;
}
</style>
