import { defineStore } from "pinia";
import axios from "axios";

export const organizationSettings = defineStore("organizationSettings", {
  state: () => ({
    data: [],
    organizationSettingsShow: false,
    logo: null,
    defaultImage: "",
    title: "",
    phone: null,
    whatsapp: "",
    provider: [],
    loading: true,
    services: [],
    website_hotel_request_type: null
  }),
  actions: {
    async getSettings() {
      try {
        const response = await axios.get("/organization_settings_index");
        this.data = response.data.data;
        this.whatsapp = response.data.data.whatsapp;
        this.title = response.data.data.title;
        this.phone = response.data.data.phone;
        this.logo = response.data.data.logo;
        this.defaultImage = response.data.data.default_image;
        this.provider = response.data.data.hotel_providers;
        this.website_hotel_request_type = response.data.data.website_hotel_request_type;
        localStorage.setItem("providers", JSON.stringify(this.provider));
        localStorage.setItem("DefaultImage", this.defaultImage);
        // Update the page title
        const titleEl = document.querySelector("head title");
        if (titleEl) {
          titleEl.textContent = response.data.data.meta_title + ' Website';
        } else {
          console.warn("Title element not found!");
        }

        // Update the meta description content
        const descriptionEl = document.querySelector('meta[name="description"]');
        if (descriptionEl) {
          descriptionEl.setAttribute('content', response?.data?.data?.meta_description || '');
          // console.log('Updated Description:', descriptionEl.getAttribute('content'));
        } else {
          console.warn('Meta description tag not found!');
        }

        // Update the favicon
        const favIcon = document.querySelector("link[rel~='icon']");
        if (favIcon) {
          favIcon.href = this.logo;      
        } else {
          console.warn("Favicon element not found!");
        }

        this.services = response?.data?.data?.services;
        this.organizationSettingsShow = true;

        // localStorage.setItem("services", JSON.stringify(this.services));
      } catch (error) {
        this.loading = false;
        console.error("Error fetching organization settings:", error);
      } finally {
        this.loading = false;
      }
    },
  },
});
