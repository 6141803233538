<template>
  <div v-if="loading" class="loading">
    <Loading />
  </div>
  <NavbarHead v-if="showNavbar" />
  <wrapper-component :customStyle="{ minHeight: '80vh' }">
    <router-view> </router-view>
  </wrapper-component>
  <Footer />
  <div
    v-if="
      (!auth && $route.name == 'bookingHotel') ||
      (!auth && $route.name == 'bookUmrah') ||
      (!auth && $route.name == 'paymentPage')
    "
    :class="`${
      showPopup
        ? 'not-register-popup d-flex justify-content-center align-center'
        : 'd-none'
    }`"
    style="z-index: 10"
  >
    <form class="content p-3 w-50" @submit.prevent="login" v-if="!visible">
      <button type="button" class="btn-close" @click="closePopup"></button>
      <div class="form-group">
        <label class="form-label mt-3" for="email">{{ $t("email") }}</label>
        <input
          type="email"
          class="form-control"
          id="email"
          v-model="email"
          :placeholder="$t('enter email')"
        />
      </div>
      <label class="form-label mt-3" for="password">{{ $t("password") }}</label>
      <div class="form-group password">
        <input
          v-if="showPassword"
          type="text"
          class="form-control"
          id="password"
          v-model="password"
          :placeholder="$t('enter password')"
        />
        <input
          v-else
          type="password"
          class="form-control"
          id="password"
          v-model="password"
          :placeholder="$t('enter password')"
        />
        <i
          class="fa-solid"
          :class="{ 'fa-eye-slash': !showPassword, 'fa-eye': showPassword }"
          @click="toggleShow"
        ></i>
      </div>
      <!-- <div class="form-group">
                          <input type="checkbox" id="remember" v-model="remember"/>
                          <label for="remember" class="mx-2">{{$t("Remember me")}}</label>
                      </div> -->
      <button class="btn PrimaryButton w-100 mt-3" type="submit">
        {{ $t("login") }}
      </button>
      <div class="d-flex flex-column align-items-center mt-3">
        <!-- <button
          type="button"
          class="btn btn-success"
          data-bs-toggle="modal"
          data-bs-target="#registerModal"
        >
          
        </button> -->
        <Button type="button" class="btn btn-success" @click="visible = true">
          {{ $t("not have account ?") }}
          <span>{{ $t("create account") }}</span>
        </Button>
        <div class="text-start mt-3 d-flex justify-content-start">
          <!-- <a href="#">{{ $t("forgot your password ?") }}</a> -->
          <resetPaword />
        </div>
      </div>
    </form>
    <!-- <Register /> -->

    <!-- modal register -->
    <Dialog
      v-if="!auth"
      v-model:visible="visible"
      :dismissableMask="true"
      modal
      :style="{ width: '50rem' }"
      :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
      class="mt-5"
    >
      <div>
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h2>{{ $t("create account") }}</h2>
              <!-- <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button> -->
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-lg-12 col-12">
                  <div class="container">
                    <!-- <div class="title">
                      <h2>
                        {{ $t("Welcome to you in website") }}
                        <span class="orange"></span>
                      </h2>
                      <p>
                        {{
                          $t(
                            "Please enter the following information to continue"
                          )
                        }}
                      </p>
                    </div> -->
                    <form @submit.prevent="register_modal">
                      <div class="row mb-4">
                        <div class="col-lg-3 col-md-3 col-12">
                          <label class="form-label" for="name_title">{{
                            $t("nickname_title")
                          }}</label>
                          <Multiselect
                            v-model="name_title"
                            :options="nicknameOptions"
                            label="value"
                            track-by="id"
                            :clear-on-select="true"
                            :placeholder="$t('choose_your_nickname')"
                            select-label=""
                            :hide-selected="true"
                          >
                          </Multiselect>
                        </div>
                        <div class="col-lg-9 col-md-9 col-12">
                          <div class="row">
                            <div class="col-lg-6 col-md-6 col-12">
                              <label class="form-label" for="first_name">{{
                                $t("first_name")
                              }}</label>
                              <input
                                type="text"
                                id="first_name"
                                v-model="first_name"
                                class="form-control"
                                :placeholder="$t('enter_first_name')"
                              />
                            </div>
                            <div class="col-lg-6 col-md-6 col-12">
                              <label class="form-label" for="last_name">{{
                                $t("last_name")
                              }}</label>
                              <input
                                type="text"
                                id="last_name"
                                v-model="last_name"
                                class="form-control"
                                :placeholder="$t('enter_last_name')"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="form-group">
                        <label class="form-label">{{ $t("birth_day") }}</label>
                        <div class="row">
                          <div class="col-lg-4 col-md-4 col-12">
                            <div class="form-group datePicker">
                              <Multiselect
                                v-model="birth_day"
                                :options="days"
                                label="day"
                                track-by="id"
                                :clear-on-select="true"
                                :placeholder="$t('day')"
                                select-label=""
                                :hide-selected="true"
                              >
                              </Multiselect>
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-4 col-12">
                            <div class="form-group datePicker">
                              <Multiselect
                                v-model="month_birth_day"
                                :options="months"
                                label="month"
                                track-by="id"
                                :clear-on-select="true"
                                :placeholder="$t('month')"
                                select-label=""
                                :hide-selected="true"
                              >
                              </Multiselect>
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-4 col-12">
                            <div class="form-group datePicker">
                              <Multiselect
                                v-model="year_birth_day"
                                :options="years"
                                label="year"
                                track-by="year"
                                :clear-on-select="true"
                                :placeholder="$t('year')"
                                select-label=""
                                :hide-selected="true"
                              >
                              </Multiselect>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="form-group">
                        <label class="form-label" for="email">{{
                          $t("email")
                        }}</label>
                        <input
                          type="email"
                          class="form-control"
                          id="email"
                          v-model="email_register"
                          :placeholder="$t('enter email')"
                        />
                      </div>

                      <div class="form-group">
                        <label class="form-label" for="phone">{{
                          $t("phone number")
                        }}</label>
                        <vue-tel-input
                          v-model="phone_register"
                          v-bind="bindProps_register"
                          mode="international"
                        ></vue-tel-input>
                      </div>

                      <div class="form-group">
                        <label class="form-label" for="nationality">{{
                          $t("Nationality")
                        }}</label>
                        <Multiselect
                          v-model="nationalityValue"
                          :options="nationalities"
                          label="title"
                          track-by="id"
                          :clear-on-select="true"
                          :placeholder="$t('choose_nationality')"
                          select-label=""
                          :hide-selected="true"
                          @keyup="searchNationality"
                        >
                        </Multiselect>
                      </div>

                      <div class="form-group passord position-relative">
                        <label class="form-label" for="password">{{
                          $t("password")
                        }}</label>
                        <input
                          v-if="showPassword_register"
                          type="text"
                          class="form-control"
                          id="password"
                          v-model="password_register"
                          :placeholder="$t('enter password')"
                        />
                        <input
                          v-else
                          type="password"
                          class="form-control"
                          id="password"
                          v-model="password_register"
                          :placeholder="$t('enter password')"
                        />
                        <i
                          class="fa-solid position-absolute"
                          :class="{
                            'fa-eye-slash': !showPassword_register,
                            'fa-eye': showPassword_register,
                          }"
                          @click="toggleShow_register"
                        ></i>
                      </div>

                      <div class="form-group">
                        <input
                          type="checkbox"
                          id="want_to_receive_emails"
                          v-model="want_to_receive_emails_register"
                        />
                        <label
                          for="want_to_receive_emails"
                          class="text_design mx-2"
                          >{{
                            $t(
                              "I would like to receive emails about the latest offers and new discounts"
                            )
                          }}</label
                        >
                      </div>

                      <p class="text_design my-4">
                        {{
                          $t(
                            "By creating an account, you agree to the Terms of Use and Privacy Policy."
                          )
                        }}
                      </p>
                      <button class="btn PrimaryButton w-100" type="submit">
                        {{ $t("login") }}
                      </button>

                      <div class="mt-3">
                        <router-link to="/login"
                          >{{ $t("Already have an accout ?") }}
                          <span>{{ $t("login") }}</span></router-link
                        >
                      </div>
                    </form>
                  </div>
                </div>
                <div class="col-lg-5 col-12">
                  <div class="image">
                    <img :src="register_image" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
    <!-- modal register -->
  </div>

  <div class="client-services">
    <div class="layout">
      <!-- ommited -->
      <SocialChat icon :attendants="attendants" dir="rtl">
        <template #header>
          <p>{{ title }} whatsapp</p>
        </template>
        <template #button>
          <img
            src="https://raw.githubusercontent.com/ktquez/vue-social-chat/master/src/icons/whatsapp.svg"
            alt="icon whatsapp"
            aria-hidden="true"
          />
        </template>
        <template #footer>
          <small></small>
        </template>
      </SocialChat>
    </div>
    <!-- <div class="water">
        <img src="@/assets/media/image/client-service1.png" />
      </div> -->
    <!-- <div class="water">
        <img src="@/assets/media/image/client-service2.png" />
      </div> -->
  </div>
  <div class="quick-services">
    <QuickBook v-if="organizationSettingsShow" />

    <!-- <div class="water">
        <img src="@/assets/media/image/client-service1.png" />
      </div> -->
    <!-- <div class="water">
        <img src="@/assets/media/image/client-service2.png" />
      </div> -->
  </div>
</template>

<script>
import NavbarHead from "@/components/layout/navbar.vue";
import Footer from "@/components/layout/footer.vue";
import { SocialChat } from "vue-social-chat";
import Loading from "@/components/LottieFiles/Loading.vue";
import axios from "axios";
// import Register from "@/components/auth/register.vue";
import WrapperComponent from "@/components/layout/WrapperComponent.vue";
import Swal from "sweetalert2";
import { organizationSettings } from "./store/modules/organizationSettings";
import { mapActions, mapState } from "pinia";
import Multiselect from "vue-multiselect";

import Dialog from "primevue/dialog";
import Button from "primevue/button";
import { onMounted, ref } from "vue";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import resetPaword from "@/components/resetPaswword/resetPawword.vue";

import { useStore } from "vuex";
import QuickBook from "@/components/global/QuickBook.vue";


export default {
  name: "App",
  data() {
    return {
      showNavbar: true,
      first_name: "",
      last_name: "",
      phone_register: "",
      email_register: "",
      password_register: "",
      want_to_receive_emails_register: false,
      showPassword: false,
      // nationalityValue: "",
      // nationalities: [],
      register_image: null,
      bindProps_register: {
        mode: "international",
        defaultCountry: "EG",
        valid: true,
        inputOptions: {
          maxlength: 20,
          type: "tel",
          placeholder: this.$t("phone number"),
        },
        validCharactersOnly: true,
      },
      name_title: "",
      month_birth_day: "",
      year_birth_day: "",
      months: [
        {
          id: 1,
          number: "01",
          month: "01" + " - " + this.$t("January"),
        },
        {
          id: 2,
          number: "02",
          month: "02" + " - " + this.$t("February"),
        },
        {
          id: 3,
          number: "03",
          month: "03" + " - " + this.$t("March"),
        },
        {
          id: 4,
          number: "04",
          month: "04" + " - " + this.$t("April"),
        },
        {
          id: 5,
          number: "05",
          month: "05" + " - " + this.$t("May"),
        },
        {
          id: 6,
          number: "06",
          month: "06" + " - " + this.$t("June"),
        },
        {
          id: 7,
          number: "07",
          month: "07" + " - " + this.$t("July"),
        },
        {
          id: 8,
          number: "08",
          month: "08" + " - " + this.$t("August"),
        },
        {
          id: 9,
          number: "09",
          month: "09" + " - " + this.$t("September"),
        },
        {
          id: 10,
          number: "10",
          month: "10" + " - " + this.$t("October"),
        },
        {
          id: 11,
          number: "11",
          month: "11" + " - " + this.$t("November"),
        },
        {
          id: 12,
          number: "12",
          month: "12" + " - " + this.$t("December"),
        },
      ],
      birth_day: "",
      days: [
        { id: 1, day: "01" },
        { id: 2, day: "02" },
        { id: 3, day: "03" },
        { id: 4, day: "04" },
        { id: 5, day: "05" },
        { id: 6, day: "06" },
        { id: 7, day: "07" },
        { id: 8, day: "08" },
        { id: 9, day: "09" },
        { id: 10, day: "10" },
        { id: 11, day: "11" },
        { id: 12, day: "12" },
        { id: 13, day: "13" },
        { id: 14, day: "14" },
        { id: 15, day: "15" },
        { id: 16, day: "16" },
        { id: 17, day: "17" },
        { id: 18, day: "18" },
        { id: 19, day: "19" },
        { id: 20, day: "20" },
        { id: 21, day: "21" },
        { id: 22, day: "22" },
        { id: 23, day: "23" },
        { id: 24, day: "24" },
        { id: 25, day: "25" },
        { id: 26, day: "26" },
        { id: 27, day: "27" },
        { id: 28, day: "28" },
        { id: 29, day: "29" },
        { id: 30, day: "30" },
        { id: 31, day: "31" },
      ],

      nicknameOptions: [
        { id: 1, value: this.$t("mr") },
        { id: 2, value: this.$t("mrs") },
        { id: 3, value: this.$t("lady") },
      ],
      email: "",
      showPopup: true,
      visible: false,
      password: "",
      remember: false,
      showPassword_register: false,
      login_image: null,
      showRegister: false,
      fullName: "",
      phone: "",
      want_to_receive_emails: false,
      nationalityValue: "",
      nationalities: [],
      bindProps: {
        mode: "international",
        defaultCountry: "EG",
        valid: true,
        inputOptions: {
          maxlength: 25,
          type: "tel",
          placeholder: this.$t("phone number"),
        },
        validCharactersOnly: true,
      },
      lat: 0,
      lng: 0,
      map: "",
    };
  },
  computed: {
    ...mapState(organizationSettings, ["loading", "title", "phone", "organizationSettingsShow"]),
    disabledDates() {
      const today = new Date();
      const nextDate = new Date();
      nextDate.setDate(today.getDate() + 1); // Set to disable dates starting from tomorrow
      return {
        from: nextDate, // Disable dates starting from tomorrow
      };
    },
    years() {
      const currentYear = new Date().getFullYear();
      const startYear = 1913;
      const yearArray = [];

      for (let year = startYear; year <= currentYear; year++) {
        yearArray.push({ id: yearArray.length + 1, year: year });
      }

      return yearArray;
    },
  },
  components: {
    QuickBook,
    NavbarHead,
    Loading,
    Footer,
    SocialChat,
    // Register,
    WrapperComponent,
    Button,
    Dialog,
    Multiselect,
    // Multiselect,
    resetPaword,
  },
  setup() {
    const desc = ref("");
    const title = ref("");
    // Use Vuex store within setup
    const store = useStore();
    const token = ref("");

    const attendants = [
      {
        app: "whatsapp",
        label: "Tec hnical support",
        // name: title.value,
        number: "00966920033253",
        avatar: {
          src: "https://avatars0.githubusercontent.com/u/8084606?s=460&u=20b6499a416cf7129a18e5c168cf387e159edb1a&v=4",
          alt: "Alan Ktquez avatar",
        },
      },
      // ...
    ];
    const firebaseConfig = {
      apiKey: "AIzaSyCHMj23-1Knt8_0eZZyRn5sUmdf19rB5vs",
      authDomain: "travel-soft-tech-119ae.firebaseapp.com",
      projectId: "travel-soft-tech-119ae",
      storageBucket: "travel-soft-tech-119ae.appspot.com",
      messagingSenderId: "124395774810",
      appId: "1:124395774810:web:cd452b3ef66f2256130963",
      measurementId: "G-8DSHPQGQJP",
    };

    initializeApp(firebaseConfig);
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      Swal.fire({
        type: "success",
        position: "top-end",
        icon: "success",
        title: payload.data.title,
        text: payload.data.body,
        showConfirmButton: true,
      });

      store.dispatch("fetchNotifications");
    });

    getToken(messaging, {
      vapidKey:
        "BAdGzvWp_YM96Jtq9hjB5NBhCl3jODofWkDcqbKU_SvM3y4uPY1ePsExafV25_1quka5vvl5vi_vePBKRN2Acj4",
    })
      .then((currentToken) => {
        if (currentToken) {
          token.value = currentToken;
          // Only call notifications if user is present
          if (localStorage.getItem("user")) {
            noteifcations();
            // console.log("token is: ", token.value);
            store.dispatch("fetchNotifications");
            // console.log("current token is: ", currentToken);
          }
        } else {
          console.log(
            "No registration token available. Request permission to generate one."
          );
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
      });

    const noteifcations = () => {
      const data = {
        device_name: "test",
        device_token: token.value,
        device_id: "1",
        device_type: "webSite",
      };
      axios.post("/store_client_device", data).then(() => {
        // console.log(data, "datea");
      });
    };

    const getMeta = async () => {
      await axios.get(`/blogs`).then((res) => {
        desc.value = res.data.data.data[0].title;
        title.value = res.data.data.data[1].title;
        // console.log(res.data.data.data[0].title, "desc");
      });
    };

    onMounted(async () => {
      await getMeta();
      // console.log(desc.value, "desc");
    });

    return {
      attendants,
      token,
      firebaseConfig,
      messaging,
      // phone,
      // title,
      noteifcations,
      getToken,
    };
    // },
  },
  methods: {
    toggleShow_register() {
      this.showPassword_register = !this.showPassword_register;
    },
    async register_modal() {
      const formData = new FormData();
      formData.append("first_name", this.first_name);
      formData.append("last_name", this.last_name);
      formData.append("name_title", this.name_title?.id);
      formData.append(
        "birth_date",
        this.year_birth_day?.year +
          "-" +
          this.month_birth_day?.number +
          "-" +
          this.birth_day?.day
      );
      formData.append("email", this.email_register);
      formData.append("password", this.password_register);
      formData.append(
        "phone_code",
        this.phone_register.split(" ")[0].replace("+", "")
      );
      formData.append(
        "phone",
        this.phone_register.split(" ").slice(1).join(" ").replace(/\s/g, "")
      );
      formData.append(
        "want_to_receive_emails",
        this.want_to_receive_emails_register == false ? 0 : 1
      );
      formData.append("nationality_id", this.nationalityValue?.id);
      this.visible = false;
      //   formData.append("nationality_id", 1);
      if (
        this.name_title === "" ||
        this.first_name === "" ||
        this.last_name === "" ||
        this.phone_register === "" ||
        this.email_register === "" ||
        this.password_register === "" ||
        this.year_birth_day === "" ||
        this.month_birth_day === "" ||
        this.birth_day === "" ||
        this.nationalityValue === ""
      ) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("Please dictate all fields"),
        });
      }
      if (this.first_name === "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("empty_first_name"),
        });
      } else if (this.last_name === "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("empty_last_name"),
        });
      } else if (this.name_title === "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("nickname field is empty"),
        });
      } else if (this.phone_register === "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("phone field is empty"),
        });
      } else if (this.email_register === "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("email field is empty"),
        });
      } else if (this.nationalityValue === "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("nationality field is empty"),
        });
      } else {
        try {
          let response = await this.$store.dispatch("Register", formData);
          // console.log(response);
          if (response.data.status == true) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: response.data.message,
            });
            this.Form = {
              phone_register: "",
              email_register: "",
              name: "",
              password_register: "",
              password_confirmation: "",
            };
            location.reload();
            this.error = null;
          } else if (response.data.status == false) {
            Swal.fire({
              position: "center",
              icon: "error",
              title: response.data.message,
            });
          }
        } catch (err) {
          //   console.log(err.response.data.message)
          Swal.fire({
            position: "center",
            icon: "error",
            title: err.response.data.message,
          });
        }
      }
    },
    ...mapActions(organizationSettings, ["getSettings"]),

    closePopup() {
      this.showPopup = false; // Set showPopup to false to close the popup
    },
    fetchTheme() {
      // if (localStorage.getItem("Theme") === 2) {
      //   import("@/assets/scss/style.min.css");
      // } else if (localStorage.getItem("Theme") === 1) {
      //   import("@/assets/new_tem/style.min.css");
      // }
      axios.get("/organization_theme").then((data) => {
        this.PrimaryColor = data.data.data.primary_color;
        this.SecondColor = data.data.data.secondary_color;
        this.NavbarColor = data.data.data.navbar_color;
        this.FooterColor = data.data.data.footer_color;
        this.HeaderColor = data.data.data.header_color;
        // console.log(this.PrimaryColor)
        // console.log(this.SecondColor)
        const style = document.createElement("style");
        style.innerHTML = `:root { --primary-color: ${this.PrimaryColor}; --second-color: ${this.SecondColor}; --header-color: ${this.HeaderColor}; --footer-color: ${this.FooterColor}; --navbar-color: ${this.NavbarColor} }`;
        document.head.appendChild(style);
        // this.updatePrimaryColor(this.PrimaryColor, this.SecondColor);
        // if (data.data.data.theme_number === 2) {
        //   import("@/assets/scss/style.min.css");
        //   localStorage.setItem("Theme", 2);
        // } else if (data.data.data.theme_number === 1) {
        //   import("@/assets/new_tem/style.min.css");
        //   localStorage.setItem("Theme", 1);
        // }
      });
    },
    // updatePrimaryColor(PrimaryColor, SecondColor) {
    //   const style = document.createElement("style");
    //   style.innerHTML = `:root { --primary-color: ${PrimaryColor}; --second-color: ${SecondColor} }`;
    //   document.head.appendChild(style);
    // },
    searchNationality(event) {
      const searchText = { word: event.target.value };
      axios
        .post(`/fetch_custom_countries`, searchText)
        .then((res) => (this.nationalities = res.data.data.data))
        .catch((err) => console.log(err));
      // console.log(searchText);
      // console.log(this.nationalities);
    },
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    async register() {
      const formData = new FormData();
      formData.append("name", this.fullName);
      formData.append("email", this.email);
      formData.append("password", this.password);
      formData.append("phone_code", this.phone.split(" ")[0].replace("+", ""));
      formData.append(
        "phone",
        this.phone.split(" ").slice(1).join(" ").replace(/\s/g, "")
      );
      formData.append(
        "want_to_receive_emails",
        this.want_to_receive_emails == false ? 0 : 1
      );
      formData.append("nationality_id", this.nationalityValue.id);
      //   formData.append("nationality_id", 1);
      if (
        this.fullName === "" ||
        this.phone === "" ||
        this.email === "" ||
        this.password === "" ||
        this.nationalityValue === ""
      ) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("Please dictate all fields"),
        });
      }
      if (this.fullName === "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("name field is empty"),
        });
      } else if (this.phone === "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("phone field is empty"),
        });
      } else if (this.email === "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("email field is empty"),
        });
        1;
      } else if (this.nationalityValue === "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("nationality field is empty"),
        });
      } else {
        try {
          let response = await this.$store.dispatch("Register", formData);
          // console.log(response);
          if (response.data.status == true) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: response.data.message,
            });
            this.Form = {
              phone: "",
              email: "",
              name: "",
              password: "",
              password_confirmation: "",
            };
            location.reload();
            this.error = null;
          } else if (response.data.status == false) {
            Swal.fire({
              position: "center",
              icon: "error",
              title: response.data.message,
            });
          }
        } catch (err) {
          //   console.log(err.response.data.message)
          Swal.fire({
            position: "center",
            icon: "error",
            title: err.response.data.message,
          });
        }
      }
    },
    async login() {
      const formData = new FormData();
      formData.append("email", this.email);
      formData.append("password", this.password);
      let response = await this.$store.dispatch("Login", formData);
      // console.log(this.phone.replace(/\s+/g, ''));
      if (this.email === "" || this.password === "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("Please dictate all fields"),
        });
      } else {
        try {
          if (response.data.status == true) {
            // console.log(response.data);
            // console.log("status" + response.data.status);
            Swal.fire({
              position: "center",
              icon: "success",
              title: response.data.message,
            });
            this.form = {
              password: "",
            };
            (this.phone = ""), this.$router.go("/");
            this.error = null;
          } else if (response.data.status == false) {
            // console.log(response.data);
            // console.log("status : " + response.data.status);
            Swal.fire({
              position: "center",
              icon: "error",
              title: response.data.message,
            });
          }
        } catch (err) {
          // console.log(err.response)
          Swal.fire({
            position: "center",
            icon: "error",
            title: err.response.data.message,
          });
        }
      }
      // if (this.rememberMe) {
      //     Cookies.set('rememberedUser', this.phone, { expires: 7 });
      // }
    },

    showLoader() {
      axios.get("/show_loader").then(({ data }) => {
        this.flight_loader = data.data.flight_loader;
        this.general_loader = data.data.general_loader;
        this.hotel_loader = data.data.hotel_loader;
        this.offer_loader = data.data.offer_loader;
        this.package_loader = data.data.package_loader;
        const loaders = {
          flight_loader: this.flight_loader,
          general_loader: this.general_loader,
          hotel_loader: this.hotel_loader,
          offer_loader: this.offer_loader,
          package_loader: this.package_loader,
        };
        // console.log(loaders)
        localStorage.setItem("loaders", JSON.stringify(loaders));
      });
    },
    getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.watchPosition((position) => {
          this.lat = position.coords.latitude;
          this.lng = position.coords.longitude;
          // console.log(this.lat);
          // console.log(this.lng);
          this.reverseGeocode(this.lat, this.lng);
        });
      }
    },
    reverseGeocode(lat, lng) {
      const apiKey = "a278e014862e4ccfa9267e416fc76083";
      const apiUrl = `https://api.opencagedata.com/geocode/v1/json?q=${lat}+${lng}&key=${apiKey}`;

      axios
        .get(apiUrl)
        .then((response) => {
          const results = response.data.results;

          if (results && results.length > 0) {
            const city = results[0].components.city;
            const country = results[0].components.country;

            this.city = city;
            this.country = country;
          } else {
            console.log("City and/or Country not found");
          }
        })
        .catch((error) => {
          console.error("Error during reverse geocoding:", error);
        });
    },
  },
  mounted() {
    this.getLocation();
  },
  created() {
    this.fetchTheme();
    this.getSettings();
    this.showLoader();
    this.auth = this.$store.getters["isAuthenticated"];
  },
};
</script>

<style lang="scss">
:root {
  --primary-color: var(--primary-color);
  --second-color: var(--second-color);
}

// @import "../src/assets/scss/style.min.css";
@import "../src/assets/new_tem/variables.scss";
@import "../src/assets/new_tem/style.min.css";
// @import "../src/assets/new_tem/variables.scss";
.not-register-popup {
  position: fixed;
  align-items: center;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba($color: #000000, $alpha: 0.2);
  .password {
    position: relative;
    i.fa-solid {
      position: absolute;
      left: 5px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .content {
    background-color: #fff;
    max-height: 400px;
    margin-top: 80px;
    border-radius: 18px;
  }
}
#app {
  background-color: #fff;
}

.p-datepicker table td > span.p-highlight {
  background-color: rgba($color: $PrimaryColor, $alpha: 0.8);
  color: $SecondColor;
  border: $SecondColor;
}
.p-datepicker table td > span:focus {
  box-shadow: 0 0 0 0.2rem $SecondColor;
}
.text p {
  color: var(--primary-color) !important;
}
</style>
