import { createRouter, createWebHistory } from "vue-router";
import store from "../store/index.js";
import getCode from "@/components/resetPaswword/genrateCode.vue";
import Password from "@/components/resetPaswword/newPassword.vue";
// import auth
import LoginPage from "../view/auth/LoginPage.vue";
import forgetPassword from "../view/auth/forgetPassword.vue";
import passwordRecovery from "../view/auth/passwordRecovery.vue";
import newPassword from "../view/auth/newPassword.vue";
import signUp from "../view/auth/signUp.vue";

//profile
import hotelBooking from "../view/profile/hotelBooking.vue";
import hotelView from "../view/hotel/hotelView.vue";
import flightView from "../view/flight/flightView.vue";
import flightDetials from "../view/flight/flightDetials.vue";
import flightBooking from "../view/profile/flightBooking.vue";
import flightBookingSingle from "../view/profile/singleHotelBooking.vue";
import flightBookingDetails from "../view/profile/flightBookingDetails.vue";

import personalData from "../view/profile/personalData.vue";
import travelPerferance from "../view/profile/travelPerferance.vue";
import securitySetting from "../view/profile/securitySetting.vue";
import pills from "../view/profile/pills.vue";

import UmrahComingSoon from "../view/umrah/UmrahComingSoon.vue";
import UmrahPackage from "../view/umrah/UmrahPackage.vue";
import UmrahPackageDetails from "../view/umrah/details.vue";
import cardSale from "../view/cardSale.vue";
import offerCode from "../view/offerCode.vue";

import homePage from "../view/home.vue";
import newHomePage from "../view/newHome.vue";
import blogsPage from "../view/blogs.vue";
import blogsCategory from "../view/blogsCategory.vue";
import blogsHashTags from "../view/blogsHashTags.vue";
import blogPage from "../view/blog.vue";
import aboutPage from "../view/about.vue";
import hotelDetialsPage from "../view/hotelDetials.vue";
import hotelSearch from "../view/hotel/hotelSearch.vue";
import hotelSearchNew from "../view/hotel/hotelSearchNew.vue";
import flightSearch from "../view/flight/flightSearch.vue";
import bookingHotel from "../view/hotel/bookingHotel.vue";
import successHotelBook from "../view/hotel/success_Hotel_Book.vue";
import cancelBookHotel from "../view/hotel/payment/failed.vue";
import flightBook from "../view/flight/flightBook.vue";

import paymentPage from "../view/payment.vue";
import successFlightPage from "../view/flight/SuccessFlightBook.vue";
import fieldFlightPage from "../view/flight/fieldFlightPage.vue";
import contactUs from "../view/contactUs.vue";
import faq from "../view/faq.vue";
import condition from "../view/condition.vue";
import terms from "../view/terms.vue";
import policy from "../view/policy.vue";
import NotFound from "../view/NotFound.vue";
// couponDetails
import couponDetails from "../view/Markters/couponDetails.vue";
import AddCoupon from "../view/Markters/coupons/AddCoupon.vue";
import Coupons from "../view/Markters/coupons/Coupons.vue";
import Points from "../view/Markters/coupons/Points.vue";
import Profits from "../view/Markters/coupons/Profits.vue";
import AllGifts from "../view/Markters/coupons/AllGifts.vue";
// couponDetails
// login_resigter
import SignIn from "../view/login_Register/signIn.vue";
import new_Acount from "../view/login_Register/newAcount.vue";
import Pemasar_Details from "../view/Markters/Pemasar_Details.vue";
import markterAdd from "../view/Markters/markterAdd.vue";
import reservation from "../view/Markters/reservation.vue";
//omrah Search
import umrahSearch from "../view/umrah/umrahSearch.vue";
import bookUmrah from "../view/umrah/bookingUmrah.vue";
import umrahBookingProfile from "../view/profile/umrahBookingProfile.vue";
import reservationDetails from "../view/umrah/reservationDetails.vue";
import umrahHotelDetails from "../view/umrah/umrahHotelDetails.vue";

import notifications from "../view/notificatios/notificationPage.vue";
import successPackageBook from "../view/umrah/success.vue";
import cancelBookPackage from "@/view/umrah/payment/failed.vue";

import cancelPayment from "@/view/payment/failed.vue";

//omrah Search
const routes = [
  // auth routes
  {
    path: "/login",
    component: LoginPage,
    name: "LoginPage",
    meta: { guest: true },
  },
  {
    path: "/forgetPassword",
    component: forgetPassword,
    name: "forgetPassword",
    meta: { guest: true },
  },
  {
    path: "/passwordRecovery",
    component: passwordRecovery,
    name: "passwordRecovery",
    meta: { guest: true },
  },
  {
    path: "/newPassword",
    component: newPassword,
    name: "newPassword",
    meta: { guest: true },
  },
  { path: "/signUp", component: signUp, name: "signUp", meta: { guest: true } },

  // login_Register
  { path: "/sign-in", component: SignIn, name: "sign-in" },
  { path: "/new-acount", component: new_Acount, name: "New Acount" },
  {
    path: "/Pemasar",
    component: Pemasar_Details,
    name: "Pemasar_Details",
  },
  {
    path: "/markterAdd",
    component: markterAdd,
    name: "markterAdd",
  },
  {
    path: "/reservation",
    component: reservation,
    name: "reservation",
  },
  // login_Register

  // coupon Details
  { path: "/coupon_details", component: couponDetails, name: "couponDetails" },
  { path: "/coupon/add", component: AddCoupon, name: "AddCoupon" },
  { path: "/coupons", component: Coupons, name: "Coupons_show" },
  { path: "/Points", component: Points, name: "Points_show" },
  { path: "/Profits", component: Profits, name: "Profits_show" },
  { path: "/AllGifts", component: AllGifts, name: "AllGifts" },
  // coupon Details

  // Website routes
  { path: "/", component: homePage, name: "homePage" },
  { path: "/newHome", component: newHomePage, name: "newHomePage" },
  { path: "/about", component: aboutPage, name: "aboutPage" },
  {
    path: "/hotel/details/:id/:page",
    component: hotelDetialsPage,
    name: "hotelDetialsPage",
  },
  { path: "/hotelSearch", component: hotelSearch, name: "hotelSearch" },
  {
    path: "/hotelSearchNew",
    component: hotelSearchNew,
    name: "hotelSearchNew",
  },
  { path: "/hotels", component: hotelView, name: "hotelView" },
  { path: "/flights", component: flightView, name: "flightView" },
  { path: "/flight-search", component: flightSearch, name: "flightSearch" },
  { path: "/flight-book", component: flightBook, name: "flightBook" },
  {
    path: "/flights/flight-pdf/:id",
    component: flightDetials,
    name: "flightDetials",
  },
  { path: "/blogs", component: blogsPage, name: "blogsPage" },
  {
    path: "/blog/category/:id",
    component: blogsCategory,
    name: "blogsCategory",
  },
  {
    path: "/blog/hashtags/:id",
    component: blogsHashTags,
    name: "blogsHashTags",
  },
  { path: "/blog/:id", component: blogPage, name: "blogPage" },
  { path: "/cardSale", component: cardSale, name: "cardSale" },
  { path: "/offer/:id", component: offerCode, name: "offerCode" },

  //profile page
  {
    path: "/hotel-booking",
    component: hotelBooking,
    name: "hotelBooking",
    meta: { requiresAuth: true },
  },
  {
    path: "/flight-booking",
    component: flightBooking,
    name: "flightBooking",
    meta: { requiresAuth: true },
  },
  {
    path: "/flight-booking-details/:id",
    component: flightBookingDetails,
    name: "flightBookingDetails",
    meta: { requiresAuth: true },
  },
  {
    path: "/hotel-booking/:id",
    component: flightBookingSingle,
    name: "flightBookingSingle",
    meta: { requiresAuth: true },
  },

  {
    path: "/personal-data",
    component: personalData,
    name: "personalData",
    meta: { requiresAuth: true },
  },
  {
    path: "/travel-performance",
    component: travelPerferance,
    name: "travelPerferance",
    meta: { requiresAuth: true },
  },
  {
    path: "/security-settings",
    component: securitySetting,
    name: "securitySetting",
    meta: { requiresAuth: true },
  },
  {
    path: "/hotel-booking/pills/:id",
    component: pills,
    name: "pills",
    meta: { requiresAuth: true },
  },

  { path: "/payment", component: paymentPage, name: "paymentPage" },
  {
    path: "/success/flight/:id",
    component: successFlightPage,
    name: "successFlightPage",
  },
  {
    path: "/hotel/bookingHotel",
    component: bookingHotel,
    name: "bookingHotel",
  },
  {
    path: "/success/hotel/:id",
    component: successHotelBook,
    name: "successHotelBook",
  },

  {
    path: "/cancel_payment",
    component: cancelPayment,
    name: "cancelPayment",
  },

  {
    path: "/success/package/:id",
    component: successPackageBook,
    name: "successPackageBook",
  },

  {
    path: "/get-code",
    component: getCode,
    name: "genrateCode",
  },
  {
    path: "/new-password",
    component: Password,
    name: "newPassword",
  },
  {
    path: "/cancel/hotel/:id",
    component: cancelBookHotel,
    name: "cancelBookHotel",
  },
  {
    path: "/cancel/flight/:id",
    component: fieldFlightPage,
    name: "fieldFlightPage",
  },
  {
    path: "/cancel/package/:id",
    component: cancelBookPackage,
    name: "cancelBookPackage",
  },

  { path: "/package/:type", component: UmrahPackage, name: "UmrahPackage" },

  {
    path: "/package_details/:id",
    component: UmrahPackageDetails,
    name: "UmrahPackageDetails",
  },
  { path: "/coming-soon", component: UmrahComingSoon, name: "UmrahComingSoon" },

  {
    path: "/notifications",
    component: notifications,
    name: "notificationsPage",
  },
  //omrah Search
  { path: "/search/package/:type", component: umrahSearch, name: "umrahSearch" },
  { path: "/bookPackage", component: bookUmrah, name: "bookUmrah" },
  {
    path: "/umrahBookingProfile",
    component: umrahBookingProfile,
    name: "umrahBookingProfile",
  },
  {
    path: "/umrahBookingProfile/:id",
    component: reservationDetails,
    name: "reservationDetails",
  },
  {
    path: "/umrah/hotel/details/:id",
    component: umrahHotelDetails,
    name: "umrahHotelDetails",
  },

  //omrah Search

  { path: "/contact", component: contactUs, name: "contactUs" },
  { path: "/faq", component: faq, name: "faq" },
  { path: "/condition", component: condition, name: "condition" },
  { path: "/terms", component: terms, name: "terms" },
  { path: "/policy", component: policy, name: "policy" },
  { path: "/404", component: NotFound },
  { path: "/:catchAll(.*)", redirect: "/404" },
];

const router = createRouter({
  routes,
  history: createWebHistory(),
  hashbang: false,
  mode: "html5",
  linkActiveClass: "active",
  // linkExactActiveClass: "exact-active",
  scrollBehavior() {
    // Scroll to the top of the page
    return window.scrollTo({ top: 0, behavior: "smooth" });
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters.isAuthenticated) {
      next("/");
      return;
    }
    next();
  } else {
    next();
  }
});

export default router;
