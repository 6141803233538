<template>
  <section class="special-offer" v-for="section in sections" :key="section.id">
    <div class="container">
      <div class="info d-flex justify-content-between align-itmes-start">
        <div class="flex-grow-1">
          <h4 class="fw-bold">{{ section.title }}</h4>
          <p
            class="fs-18 fw-light text-secondary"
            v-html="section.description"
          ></p>
        </div>
      </div>
      <div class="hotel-wrapper">
        <div
          :class="[
            'hotel-card',
            'p-0',
            'flex-column',
            'text-black',
            'text-start',
            'pb-2',
          ]"
          v-for="(item, index) in section.hotels"
          :key="index"
        >
          <div class="cursor-pointer" @click="routeToHotel(item)">
            <div class="img-container">
              <img
                :src="
                  item.images[0]?.image
                    ? item.images[0].image
                    : item.images[1]?.image
                "
                @error="setDefaultImage"
                :class="{ 'error-image': isError }"
              />
            </div>
            <h6 class="mt-2 fw-bold px-3 title-line text-black">
              {{ item.title }}
            </h6>
            <div class="rates d-flex align-item-center mt-2 px-3">
              <rate :rates="item.star_count == 0 ? 5 : item.star_count" />
            </div>
            <div class="d-flex align-items-start px-3 pt-2 address">
              <i class="fa-solid fa-location-dot text-black"></i>
              <p class="fs-12 text-secondary mx-1 address address-line">
                {{ item.address }}
              </p>
            </div>
            <div class="d-flex align-items-start px-2 pt-2 address">
              <ul class="facilities mb-0">
                <li v-for="(facility, index) in item.facilities" :key="index" :tooltip="facility.title" flow="up">
                  <i :class="`fa-solid fa-${facility.icon}`"></i>
                </li>
              </ul>
            </div>
            <!-- <div
              class="d-flex align-items-center justify-content-between px-3 gap-2 py-3 likes"
            >
              <img src="@/assets/media/image/fi_535190.png" alt="" />
              <p class="fs-12 text-white fw-bold mx-1">555 تقيمات ايجابية</p>
              <img src="@/assets/media/image/Icon.png" alt="" />
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
// Import Swiper Vue.js components
import moment from "moment";

// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";

// import required modules
import axios from "axios";
import rate from "../rate.vue";

export default {
  data() {
    return {
      isError: false,

      sections: [],
    };
  },
  components: {
    rate,
  },
  methods: {
    setDefaultImage(event) {
      event.target.src = localStorage.getItem("DefaultImage");
      this.isError = true; // Set isError to true when an error occurs
    },
    routeToHotel(hotel) {
      const dataHotel = {
        "result_index":null,
        "session_id":null,
        "nationality_code": "SA",
        "hotel_name": hotel.title,
        "passenger_country_of_residence_code":"SA",
        "rooms_guests":[
          {
            "rate_basis":"1",
            "adults_count":1,
            "children_count":0,
            "children_ages":[

            ]
          }
        ],
        "check_in": moment(new Date()).format("YYYY-MM-DD"),
        "check_out": moment(new Date(new Date().getTime() + 24 * 60 * 60 * 1000)).format("YYYY-MM-DD"),
        "no_of_rooms":1,
        "meta_data":{
          "credential_id": hotel.providers[0]?.provider_id,
          "provider_id": hotel.providers[0]?.provider_id
        },
        "hotel": hotel.code
      }
      localStorage.setItem("hotelData", JSON.stringify(dataHotel));

      const dataHotelSearch = {
        "city_id":169201,
        "city_name":"مكه",
        "country_name":"المملكة العربية السعودية",
        "country_code":"SA",
        "nationality_code":"SA",
        "no_of_rooms":1,
        "passenger_country_of_residence_code":"SA",
        "converter_status":1,
        "rooms_guests":[
          {
            "rate_basis":"1",
            "adults_count":1,
            "children_count":0,
            "children_ages":[

            ]
          }
        ],
        "check_in": moment(new Date()).format("YYYY-MM-DD"),
        "check_out": moment(new Date(new Date().getTime() + 24 * 60 * 60 * 1000)).format("YYYY-MM-DD"),
        "meta_data":{
          "credential_id": hotel.providers[0]?.provider_id,
          "provider_id": hotel.providers[0]?.provider_id
        }
      }
      localStorage.setItem("hotelDataSearch", JSON.stringify(dataHotelSearch));
      this.$router.push(`/hotel/details/${hotel.code}/1`);
    },
    async fetchSections() {
      // Get date for tomorrow
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);

      // Get date for the day after tomorrow
      const nextDay = new Date();
      nextDay.setDate(nextDay.getDate() + 2);

      await axios.post("/hotel_card_website").then((data) => {
        this.sections = data.data.data;
        console.log(this.sections, "sections");

        let hotelData = {
          nationality_code: "SA",
          passenger_country_of_residence_code: "SA",
          check_in: moment(tomorrow).format("YYYY-MM-DD"),
          check_out: moment(nextDay).format("YYYY-MM-DD"),
          mete_data: {
            credential_id: 2,
            provider_id: 2,
          },
          converter_status: 1,
          rooms_guests: [
            {
              rate_basis: 1,
              adults_count: 1,
              children_count: 0,
              children_ages: [],
            },
          ],
        };

        localStorage.setItem("hotelData", JSON.stringify(hotelData));
      });
    },
  },
  async created() {
    await this.fetchSections();
  },
};
</script>

<style scoped>
  .cursor-pointer {
    cursor: pointer;
  }
  .facilities {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    font-size: 0.9rem;
    height: 25px
  }
</style>