<template>
  <umraSearchResult @fetchData="fetchPackage" />
  <div v-if="loading" class="loading">
    <Loading />
  </div>
  <div class="main-nextpage" style="margin-top: 4rem">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 col-md-12 col-12">
          <div class="text-center" v-if="packages.length == 0">
            <img src="@/assets/media/image/no_results.png" />
          </div>
          <div
            class="card-nextpage"
            v-else
            v-for="(packageUmrah, index) in packages"
            :key="index"
          >
            <div class="photo-nextpage">
              <img :src="packageUmrah.main_image" alt="" />
            </div>
            <div class="content">
              <div class="title">
                <div>
                  <h4>{{ packageUmrah.title }}</h4>
                  <p>{{ packageUmrah.description }}</p>
                  <!-- <p>{{
                      packageUmrah.description.split(" ").length <= 5
                        ? packageUmrah?.description
                        : packageUmrah?.description
                            .split(" ")
                            .slice(0, 7)
                            .join(" ") + "..."
                    }}
                  </p> -->
                </div>
                <div class="premium">
                  <span>{{ packageUmrah.star_category?.title }}</span>
                  <rate :rates="packageUmrah.star_category?.star_count"></rate>
                </div>
              </div>

              <ul class="services">
                <li
                  v-for="(service, index) in packageUmrah.package_services"
                  :key="index"
                >
                  <i
                    class="fa-solid"
                    :class="{
                      'fa-hotel': service == 'Hotel',
                      'fa-plane-departure': service == 'Flight',
                      'fa-passport': service == 'Visa',
                      'fa-car': service == 'Transportation',
                      'fa-shield': service == 'Insurance',
                    }"
                  ></i>
                  <span>{{ $t(`${service}`) }}</span>
                </li>
              </ul>

              <div class="details">
                <div>
                  <div class="destinations">
                    <div
                      class="dist"
                      v-for="dist in packageUmrah.destinations"
                      :key="dist.id"
                    >
                      <i class="fa-solid fa-location-dot"></i>
                      {{ dist.city.title }} , {{ dist.nights_count }}
                      {{ $t("nights") }}
                    </div>
                  </div>

                  <div v-for="hotel in packageUmrah.hotels" :key="hotel.id">
                    <div>
                      <i class="fa-solid fa-hotel"></i>
                      <span>{{ hotel.title }}</span>
                    </div>
                  </div>

                  <div
                    class="flight"
                    v-for="flight in packageUmrah.flight"
                    :key="flight.id"
                  >
                    <div class="">
                      <i class="fa-solid fa-plane-up"></i>
                      <span>{{ flight.launch_airport.city.title }}</span>
                      <i class="fa-solid fa-plane"></i>
                      <span>{{ flight.arrival_airport.city.title }}</span>
                    </div>
                  </div>
                </div>
                <div class="">
                  <div class="prices">
                    {{ $t("start from") }}
                    <span class="value">
                      {{ packageUmrah.min_price_room.adult }}
                    </span>
                  </div>

                  <router-link
                    :to="`/package_details/${packageUmrah.id}`"
                    type="button"
                    class="btn btn-success show"
                  >
                    {{ $t("show details") }}
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 col-12">
          <div class="left-sec">
            <div class="hed-nextpage">
              <h4>{{ $t("search_filter") }}</h4>
            </div>
            <div class="inputs">
              <Multiselect
                v-model="cities_value"
                :options="citiesSearch"
                label="title"
                track-by="id"
                :clear-on-select="true"
                :placeholder="
                  $t('Search by city name (you can select more than one city)')
                "
                select-label=""
                :hide-selected="true"
                :multiple="true"
                @keyup="searchCity"
                @change="filter_data"
              >
              </Multiselect>
            </div>
            <div class="type">
              <div class="accordion accordion-flush" id="accordionFlushExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingOne">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                    >
                      {{ $t("type") }}
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    class="accordion-collapse collapse show"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                      <div
                        class="form-check"
                        v-for="star in star_category"
                        :key="star.id"
                      >
                        <input
                          class="form-check-input"
                          type="radio"
                          :value="star.id"
                          :id="star.id"
                          name="type"
                          v-model="filter_type"
                          @change="filter_data"
                        />
                        <label class="form-check-label" :for="star.id">
                          {{ star.title }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="service">
              <div class="accordion accordion-flush" id="service">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-service">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#service"
                      aria-expanded="false"
                      aria-controls="service"
                    >
                      {{ $t("services") }}
                    </button>
                  </h2>
                  <div
                    id="service"
                    class="accordion-collapse collapse show"
                    aria-labelledby="flush-service"
                    data-bs-parent="#service"
                  >
                    <div class="accordion-body">
                      <div class="form-check" @change="selectAll()">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="select_all"
                          value="true"
                          v-model="select_all"
                        />
                        <label class="form-check-label" for="select_all">
                          {{ $t("select_all") }}
                        </label>
                      </div>

                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="2"
                          id="flight"
                          v-model="filter_services"
                          @change="filter_data"
                        />
                        <label class="form-check-label" for="flight">
                          {{ $t("Flight") }}
                        </label>
                      </div>

                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="1"
                          id="hotel"
                          v-model="filter_services"
                          @change="filter_data"
                        />
                        <label class="form-check-label" for="hotel">
                          {{ $t("Hotels") }}
                        </label>
                      </div>

                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="3"
                          id="transportation"
                          v-model="filter_services"
                          @change="filter_data"
                        />
                        <label class="form-check-label" for="transportation">
                          {{ $t("transportation") }}
                        </label>
                      </div>

                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="4"
                          id="insurance"
                          v-model="filter_services"
                          @change="filter_data"
                        />
                        <label class="form-check-label" for="insurance">
                          {{ $t("Insurance") }}
                        </label>
                      </div>

                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="5"
                          id="visa"
                          v-model="filter_services"
                          @change="filter_data"
                        />
                        <label class="form-check-label" for="visa">
                          {{ $t("Visa") }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="days">
              <div class="accordion accordion-flush" id="accordionFlushExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-day">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-day"
                      aria-expanded="false"
                      aria-controls="flush-day"
                    >
                      {{ $t("number_of_days") }}
                    </button>
                  </h2>
                  <div
                    id="flush-day"
                    class="accordion-collapse collapse show"
                    aria-labelledby="flush-day"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                      <div class="form-group">
                        <input
                          type="number"
                          class="form-control"
                          v-model="day_count"
                          @keyup="filter_data"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="filter_price">
              <div class="accordion accordion-flush" id="price">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-price">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-price"
                      aria-expanded="false"
                      aria-controls="flush-price"
                    >
                      {{ $t("price") }}
                    </button>
                  </h2>
                  <div
                    id="flush-price"
                    class="accordion-collapse collapse show"
                    aria-labelledby="flush-price"
                    data-bs-parent="#price"
                  >
                    <div class="accordion-body">
                      <div class="form-group">
                        <p class="price_title">{{ $t("price") }}</p>
                        <vue-slider
                          v-model="priceValue"
                          :min="minValue"
                          :max="maxValue"
                          :step="stepValue"
                          :lazy="true"
                          direction="rtl"
                          :tooltip="'always'"
                          :tooltip-placement="['top', 'bottom']"
                          @change="filter_data"
                        ></vue-slider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <button
              type="button"
              class="btn PrimaryButton mt-3"
              @click="filter_data"
            >
              {{ $t("update_results") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <pagination
      :data="packages"
      class="mx-auto d-flex align-items-center justify-content-center pagination"
      @pagination-change-page="fetchPackage"
    >
      <template v-slot:prev-nav>&lt;</template>
      <template v-slot:next-nav>&gt;</template>
    </pagination>
  </div>
</template>

<script>
import umraSearchResult from "./umrahSearchResult.vue";
import axios from "axios";
import Swal from "sweetalert2";
import Loading from "@/components/LottieFiles/flightSearchLoading.vue";
import rate from "@/components/rate.vue";
import Multiselect from "vue-multiselect";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";

export default {
  name: "omrah-search",
  data() {
    return {
      packages: [],
      coinCode:
          localStorage.getItem("coinCode")
              ? localStorage.getItem("coinCode")
              : JSON.parse(localStorage.getItem("coinCodes"))[0]?.title || "USD",
      loading: false,
      citiesSearch: [],
      cities_value: [],
      day_count: 0,
      price: 50,
      filter_services: [],
      filter_type: "",
      star_category: [],
      priceValue: [2000, 3000], // Initial range values
      minValue: 100, // Minimum value
      maxValue: 5000, // Maximum value
      stepValue: 100, // Step increment
      select_all: false,
    };
  },
  components: {
    umraSearchResult,
    Loading,
    rate,
    Multiselect,
    VueSlider,
  },
  methods: {
    fetchPackage(page = 1) {
      // console.log("fetch");
      this.loading = true;
      const umrahPackage = JSON.parse(sessionStorage.getItem("packageData"));

      axios
        .post(`/package_filter?page=${page}`, umrahPackage)
        .then(({ data }) => {
          this.packages = data.data.map((ele) => {
            return {
              ...ele,
              selected_room: {},
            };
          });
          console.log(data.data);
          this.loading = false;
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error?.response?.data?.message || "Unknown error",
            icon: "error",
          });
          this.loading = false; //the loading ended
          this.offcanvasModal = false;
        });
    },
    filter_data() {
      this.loading = true;

      const umrahPackage = JSON.parse(sessionStorage.getItem("packageData"));

      let fliterdData = {
        ...umrahPackage,
        price_from: this.priceValue[0],
        price_to: this.priceValue[1],
      };
      if (this.cities_value.length > 0) {
        fliterdData.city_id = this.cities_value.map((city) => {
          return city.id;
        });
      }
      if (this.filter_services.length > 0) {
        fliterdData.includes = this.filter_services;
      }
      if (this.filter_type != "") {
        fliterdData.star_count = this.filter_type;
      }
      if (this.day_count != 0) {
        fliterdData.day_count = this.fliterdData;
      }

      axios
        .post(`/package_filter`, fliterdData)
        .then(({ data }) => {
          this.packages = data.data;
          this.loading = false;
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error?.response?.data?.message || "Unknown error",
            icon: "error",
          });
          this.loading = false; //the loading ended
          this.offcanvasModal = false;
        });
    },
    searchCity(event) {
      const searchText = { word: event.target.value };
      axios
        .post(`/search_home_hotels`, searchText)
        .then((res) => (this.citiesSearch = res.data.data.cities))
        .catch((err) => console.log(err));
      // console.log(searchText);
    },
    fetch_star_category() {
      axios
        .post(`/fetch_star_category`)
        .then(({ data }) => {
          // console.log(data.data.data)
          this.star_category = data.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    selectAll() {
      this.filter_data();

      if (this.filter_services.length < 5) {
        this.filter_services = ["1", "2", "3", "4", "5"];
        this.select_all = true;
      } else {
        this.filter_services = [];
        this.select_all = false;
      }
    },
  },
  created() {
    this.fetchPackage();
    this.fetch_star_category();
  },
};
</script>

<!-- <style lang="scss" scoped>
@import url("./_umrahSearch.scss");

</style> -->
