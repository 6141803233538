<template>
  <section class="contact-us">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <form @submit.prevent="sendData()">
            <div class="main_title">
              <h2>{{ $t("contact_us") }}</h2>
              <p>
                {{ $t("Do you have questions or suggestions? !") }} <br />
                {{ $t("let's talk") }}
              </p>
            </div>
            <div class="form-group">
              <label for="name" class="form-label"
                ><i class="fa-regular fa-user"></i> {{ $t("full name") }}</label
              >
              <input
                type="text"
                class="form-control"
                v-model="full_name"
                id="name"
              />
            </div>
            <div class="form-group">
              <label for="email" class="form-label"
                ><i class="fa-regular fa-envelope"></i>
                {{ $t("email address") }}</label
              >
              <input
                type="email"
                class="form-control"
                v-model="email"
                id="email"
              />
            </div>
            <div class="form-group">
              <label for="message" class="form-label"
                ><i class="fa-regular fa-envelope-open"></i>
                {{ $t("Message text") }}</label
              >
              <textarea
                type="message"
                class="form-control"
                v-model="message"
                id="message"
                :placeholder="$t('Write Message text Here')"
              ></textarea>
            </div>
            <div class="form-group">
              <!-- <googleCaptcha /> -->
            </div>
            <button class="btn PrimaryButton mt-3" type="submit">
              {{ $t("confirm") }}
            </button>
          </form>
        </div>
        <div class="col-lg-6 col-md-12 text-center">
          <img :src="contact_us_image" alt="image" />
        </div>
        <div class="row mt-5" v-if="data">
          <div class="col-lg-4 col-md-6 col-12">
            <div class="contact">
              <h3 class="title">
                <i class="fa-solid fa-phone-flip"></i> {{ $t("call now") }}
              </h3>
              <a :href="('tel:', data.phone)">{{ data.phone }}</a>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-12">
            <div class="contact">
              <h3 class="title">
                <i class="fa-regular fa-envelope"></i> {{ $t("email address") }}
              </h3>
              <a :href="('mailto:', data.email)">{{ data.email }}</a>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-12">
            <div class="contact">
              <h3 class="title">
                <i class="fa-solid fa-location-dot"></i>
                {{ $t("main addresses") }}
              </h3>
              <p>{{ data.address }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { organizationSettings } from "@/store/modules/organizationSettings";
import { mapState } from "pinia";
// import googleCaptcha from '@/view/googleCaptcha.vue';

export default {
  name: "contactUs",
  data() {
    return {
      full_name: "",
      email: "",
      message: "",
      contact_us_image: null,
    };
  },
  computed: {
    ...mapState(organizationSettings, ["data"]),
  },
  components: {
    // googleCaptcha
  },
  methods: {
    sendData() {
      const formData = new FormData();
      formData.append("full_name", this.full_name);
      formData.append("email", this.email);
      formData.append("message", this.message);
      formData.append("website_link", window.location.origin);

      axios.post("/contact_us_store", formData).then(({ data }) => {
        Swal.fire({
          title: "",
          text: data.message,
          icon: "success",
        });
        (this.full_name = ""), (this.email = ""), (this.message = "");
      });
    },
    webSiteHeaders() {
      axios
        .get(`/fetch_web_site_headers`)
        .then((res) => (this.contact_us_image = res.data.data.contact_image))
        .catch((err) => console.log(err));
      // console.log(searchText);
    },
  },
  mounted() {
    this.webSiteHeaders();
  },
};
</script>

<!-- <style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";
  @import "./profile/_contactUs.scss";
</style> -->
